.exercise-page {
  font-family: Arial, sans-serif;
}

.banner {
  position: relative;
  text-align: center;
  color: white;
}

.banner img {
  width: 100%;
  max-height: 50vh;
  object-fit: cover;
}

.banner h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2.5rem;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
  padding: 1rem;
}

.categories {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 2rem 0;
}

.category {
  flex: 1 0 21%;
  margin: 1rem;
  text-align: center;
  cursor: pointer;
  transition: transform 0.3s ease;
  text-decoration: none;
  color: inherit;
}

.category img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.category h2 {
  margin-top: 0.5rem;
  font-size: 1.2rem;
}

.category:hover {
  transform: scale(1.05);
}

.workout-info h3 {
  color: black;
}
