.workout-category-page {
    font-family: Arial, sans-serif;
    padding: 2rem;
  }
  
  .category-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #ddd;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
  }
  
  .back-button {
    background: transparent;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
    text-decoration: none;
    color: #007bff;
  }
  
  .workouts-list {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
  }
  
  .workout-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 0 45%;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 1rem;
    background: #f9f9f9;
  }
  
  .workout-item img {
    width: 100px;
    height: 100px;
    margin-bottom: 1rem;
  }
  
  .workout-info {
    text-align: center;
  }
  
  .workout-info h3 {
    margin: 0;
    font-size: 1.4rem;
  }
  
  .workout-info p {
    font-size: 1rem;
    color: #666;
  }
  