body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* ditch the ugle blue outline */
.navbar-toggler:focus,
.navbar-toggler:active {
    outline: 0;
}

/* hide close when burger shown */
.navbar-toggler.collapsed .close-icon {
    display: none;
}

.navbar-toggler:not(.collapsed) .navbar-toggler-icon {
  display: inline;
}

@media screen and (max-width: 991px) {
  .custom-length {
      min-width: 100%;
  }
}

.video_popup{
  color: black;
  word-break: break-all;
  padding: 0 10px;
  
  }
  .video_popup video{
      width: 450px;
      height: 450px;
  }

  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px !important;
    outline: 0;
}

.active-btn {
  color: white;
  background-color: rgb(0, 216, 72);
  font-size: 16px;
  font-weight: 500;
  font-family: Poppins, sans-serif;
  border: 1px solid rgb(0, 216, 72);
  padding: 5px 19px;
  border-radius: 25px;
  transition: all 0.3s ease-in-out 0s;
}

.center-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh; /* Ensures the container takes at least the full viewport height */
  margin-top: 70px;
}

.center-table {
  margin: 20px auto; /* Centers the table horizontally */
  /* Additional styling for the table */
}

.workout-day-container {
  border: 2px solid #ccc; /* Example border */
  padding: 20px; /* Space inside the box */
  margin: 20px 0; /* Space outside the box */
  width: 80%; /* Adjust width as needed */
  /* background-color: #f9f9f9;  */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Optional: Adds a subtle shadow */
  text-align: center; /* Centers the text */
}
